import React from "react";
import {
  RiEmotionHappyLine,
  RiHandHeartLine,
  RiTimerLine,
  RiGameLine,
  RiEyeOffLine,
} from "react-icons/ri";
// import RiLockLine from "react-icons/ri/lockline";
// import RiHandHeartLine from "react-icons/lib/ri/rest-time-line";
// import RiAlertLine from "react-icons/ri";
// import RiTimerLine from "react-icons/ri/timer-line";
// import RiNotificationBadgeLine from "react-icons/ri/notification-badge-line";
// import RiTeamLine from "react-icons/ri/team-line";
// import RiVipCrownFill from "react-icons/ri/";
import { View, Text, Spacer } from "ollui";
import { Section } from "./section";
import blob from "../img/blob.svg";

const features = [
  {
    icon: <RiHandHeartLine size={24} />,
    title: "It is free",
    description:
      "Stenkolle will do our best to stay free and the hope is to survive solely on the good intentions of our users by providing opt-in donations.",
  },
  {
    icon: <RiEyeOffLine size={24} />,
    title: "Privacy",
    description:
      "Few have a need to precisely identify each user that visits our sites. Stenkolle automatically anonymizes all visitors and you do not have to worry about the integrity of this data.",
  },
  {
    icon: <RiGameLine size={24} />,
    title: "Less is more",
    description:
      "Do you really need all the functionality of Google Analytics? Stenkolle provides the functionality you really need in a nice and simple manner.",
  },
  {
    icon: <RiEmotionHappyLine size={24} />,
    title: "No need for cookie or consent banners",
    description:
      "Since Stenkolle does not use cookies or any other way of storing user identifiable data, you will not have to add or update your cookie/consent banners after adding Stenkolle to your site.",
  },
  {
    icon: <RiTimerLine size={24} />,
    title: "Keep your site light",
    description:
      "The Stenkolle tracker script is very tiny, to keep the effect on your site as minimal as possible.",
  },
  // {
  //   icon: <RiTeamLine size={24} />,
  //   title: "Feature 5",
  //   description: "Feature 5 description.",
  // },
  // {
  //   icon: <RiAlertLine size={34} />,
  //   title: "",
  //   description: "",
  // },
  // {
  //   icon: <RiAlertLine size={34} />,
  //   title: "",
  //   description: "",
  // },
];

export const Features = () => (
  <Section
    heading="Features"
    subHeading="Why Stenkolle might be something for you"
    id="features"
    extend={rootCSS}
    variant="light"
    bottomWave={{
      viewBox: "0 0 1440 320",
      content: (
        // <path
        //   fill="#fff"
        //   fill-opacity="1"
        //   d="M0,0L24,16C48,32,96,64,144,101.3C192,139,240,181,288,186.7C336,192,384,160,432,149.3C480,139,528,149,576,149.3C624,149,672,139,720,128C768,117,816,107,864,96C912,85,960,75,1008,64C1056,53,1104,43,1152,64C1200,85,1248,139,1296,138.7C1344,139,1392,85,1416,58.7L1440,32L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z"
        // ></path>
        // <path
        //   fill="#fff"
        //   fill-opacity="1"
        //   d="M0,0L10.4,16C20.9,32,42,64,63,85.3C83.5,107,104,117,125,112C146.1,107,167,85,188,69.3C208.7,53,230,43,250,37.3C271.3,32,292,32,313,53.3C333.9,75,355,117,376,138.7C396.5,160,417,160,438,170.7C459.1,181,480,203,501,224C521.7,245,543,267,563,266.7C584.3,267,605,245,626,250.7C647,256,668,288,689,298.7C709.6,309,730,299,751,277.3C772.2,256,793,224,814,202.7C834.8,181,856,171,877,170.7C897.4,171,918,181,939,192C960,203,981,213,1002,186.7C1022.6,160,1043,96,1064,74.7C1085.2,53,1106,75,1127,69.3C1147.8,64,1169,32,1190,21.3C1210.4,11,1231,21,1252,48C1273,75,1294,117,1315,112C1335.7,107,1357,53,1377,26.7C1398.3,0,1419,0,1430,0L1440,0L1440,320L1429.6,320C1419.1,320,1398,320,1377,320C1356.5,320,1336,320,1315,320C1293.9,320,1273,320,1252,320C1231.3,320,1210,320,1190,320C1168.7,320,1148,320,1127,320C1106.1,320,1085,320,1064,320C1043.5,320,1023,320,1002,320C980.9,320,960,320,939,320C918.3,320,897,320,877,320C855.7,320,835,320,814,320C793,320,772,320,751,320C730.4,320,710,320,689,320C667.8,320,647,320,626,320C605.2,320,584,320,563,320C542.6,320,522,320,501,320C480,320,459,320,438,320C417.4,320,397,320,376,320C354.8,320,334,320,313,320C292.2,320,271,320,250,320C229.6,320,209,320,188,320C167,320,146,320,125,320C104.3,320,83,320,63,320C41.7,320,21,320,10,320L0,320Z"
        // ></path>
        // <path
        //   fill="#fff"
        //   fill-opacity="1"
        //   d="M0,64L15,96C30,128,60,192,90,218.7C120,245,150,235,180,229.3C210,224,240,224,270,218.7C300,213,330,203,360,197.3C390,192,420,192,450,197.3C480,203,510,213,540,186.7C570,160,600,96,630,58.7C660,21,690,11,720,37.3C750,64,780,128,810,133.3C840,139,870,85,900,58.7C930,32,960,32,990,37.3C1020,43,1050,53,1080,58.7C1110,64,1140,64,1170,90.7C1200,117,1230,171,1260,192C1290,213,1320,203,1350,170.7C1380,139,1410,85,1425,58.7L1440,32L1440,320L1425,320C1410,320,1380,320,1350,320C1320,320,1290,320,1260,320C1230,320,1200,320,1170,320C1140,320,1110,320,1080,320C1050,320,1020,320,990,320C960,320,930,320,900,320C870,320,840,320,810,320C780,320,750,320,720,320C690,320,660,320,630,320C600,320,570,320,540,320C510,320,480,320,450,320C420,320,390,320,360,320C330,320,300,320,270,320C240,320,210,320,180,320C150,320,120,320,90,320C60,320,30,320,15,320L0,320Z"
        // ></path>
        <path
          fill="#fff"
          fill-opacity="1"
          d="M0,32L21.8,64C43.6,96,87,160,131,165.3C174.5,171,218,117,262,85.3C305.5,53,349,43,393,37.3C436.4,32,480,32,524,69.3C567.3,107,611,181,655,192C698.2,203,742,149,785,160C829.1,171,873,245,916,261.3C960,277,1004,235,1047,224C1090.9,213,1135,235,1178,245.3C1221.8,256,1265,256,1309,218.7C1352.7,181,1396,107,1418,69.3L1440,32L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"
        ></path>
      ),
    }}
  >
    <View extend={featuresCSS}>
      {features.map(({ icon, title, description }) => (
        <View extend={featureCSS} key={title}>
          <View extend={iconCSS}>
            <View as="img" src={blob} extend={blobCSS} />
            <View extend={{ zIndex: 1 }}>{icon}</View>
          </View>
          <View extend={textCSS}>
            <Text variant="medium" bold color="white">
              {title}
            </Text>
            <Spacer />
            <Text color="white">{description}</Text>
          </View>
        </View>
      ))}
    </View>
  </Section>
);

const rootCSS = ({ theme: { colors, grid } }) => ({
  // position: "relative",
  // background: colors.primary,
  background: `linear-gradient(0deg, ${colors.diffuse} 0%, ${colors.primary} 100%)`,
});

const featuresCSS = ({ theme: { breakpoints, grid } }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gridGap: 8 * grid,
  padding: `0 ${8 * grid}px`,
  // background: "green",

  [breakpoints.onlyS]: {
    gridTemplateColumns: "1fr",
    gridGap: 6 * grid,
    padding: `0 ${2 * grid}px`,
  },
});

const featureCSS = ({ theme: { breakpoints } }) => ({
  display: "flex",
  textAlign: "left",

  [breakpoints.onlyS]: {
    // flexDirection: "column",
    // textAlign: "center",
  },
});

const iconCSS = ({ theme: { colors, grid, breakpoints, radius } }) => ({
  position: "relative",
  color: colors.primary,
  marginRight: 2 * grid,
  width: 80,
  height: 80,
  // background: colors.white,
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: 8,
  // borderRadius: radius,
  // border: `4px solid ${colors.border}`,

  // [breakpoints.onlyS]: {
  //   marginRight: 0,
  //   marginBottom: 2 * grid,
  // },
});
const blobCSS = () => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  zIndex: 0,
  // height: 200,
  // background: "red",
});

const textCSS = () => ({
  // textAlign: "left",
});
