import React from "react";
import { Link } from "gatsby";
import { View, Text, Spacer, Link as CustomLink } from "ollui";

import logo from "../img/logo_center.svg";
// import facebook from "../img/social/facebook.svg";
// import instagram from "../img/social/instagram.svg";
// import twitter from "../img/social/twitter.svg";
// import vimeo from "../img/social/vimeo.svg";
import heroImage from "../img/footer.svg";
import { Section } from ".";

export const Footer = () => {
  return (
    <Section as="footer" extend={rootCSS}>
      <View extend={contentCSS}>
        <View as="img" extend={logoCSS} src={logo} alt="Stenkolle" />
        <Spacer size={6} />
        <View as="ul" extend={linksCSS}>
          <li>
            <CustomLink extend={linkCSS} as={Link} to="/">
              <Text bold>Home</Text>
            </CustomLink>
          </li>
          <li>
            <CustomLink extend={linkCSS} as={Link} to="/blog">
              <Text bold>Blog</Text>
            </CustomLink>
          </li>
          {/* <li>
            <CustomLink extend={linkCSS} as={Link} to="/privacy">
              <Text bold>Privacy Policy</Text>
            </CustomLink>
          </li>
          <li>
            <CustomLink extend={linkCSS} as={Link} to="/terms">
              <Text bold>Terms of Service</Text>
            </CustomLink>
          </li> */}
        </View>
      </View>
      <View as="img" src={heroImage} extend={heroImageCSS} />
    </Section>
  );
};

const rootCSS = ({ theme: { colors, layout, breakpoints } }) => ({
  position: "relative",
  // background: colors.contentBg,
  maxWidth: layout.maxContentWidth,
  margin: "0 auto",
  // padding: `${16 * grid}px ${4 * grid}px`,
  display: "flex",
  flexDirection: "column",

  [breakpoints.fromM]: {
    paddingBottom: 0,
  },
});

const logoCSS = ({ theme: { breakpoints } }) => ({
  position: "relative",
  zIndex: 1,
  width: "75%",
  maxWidth: 300,

  [breakpoints.onlyS]: {
    width: "50%",
  },
});

const contentCSS = ({ theme: { grid, breakpoints } }) => ({
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: -20 * grid,
  zIndex: 1,
});

const linksCSS = ({ theme: { breakpoints } }) => ({
  display: "flex",

  [breakpoints.onlyS]: {
    flexDirection: "column",
    alignItems: "center",
  },
});

const linkCSS = () => ({
  textDecoration: "none",
});

const heroImageCSS = ({ theme: { colors, grid } }) => ({
  position: "relative",

  // bottom: 0,
  // left: 0,
  // width: "100%",
  // zIndex: 0,
  // borderRadius: 20,
  // width: "100vw",
  // height: 400px
  // left: "50%",
  // right: "50%",
  // margin: "0 -50vw",
  // padding: 4 * grid,
});
