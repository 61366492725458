import React from "react";
import { Link } from "gatsby";
import { View, Text, Link as OlluiLink, Button, Spacer } from "ollui";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import logo from "../img/logo_center.svg";

{
  /* <AnchorLink to="/about#team" title="Our team">
<span>Check out our team!</span>
</AnchorLink> */
}

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  // toggleHamburger = () => {
  //   // toggle the active boolean in the state
  //   this.setState(
  //     {
  //       active: !this.state.active,
  //     },
  //     // after state has been updated,
  //     () => {
  //       // set the class in state for the navbar accordingly
  //       this.state.active
  //         ? this.setState({
  //             navBarActiveClass: "is-active",
  //           })
  //         : this.setState({
  //             navBarActiveClass: "",
  //           });
  //     }
  //   );
  // };

  render() {
    return (
      <View extend={rootCSS}>
        <View
          extend={containerCSS}
          as="nav"
          role="navigation"
          aria-label="main-navigation"
        >
          <View extend={linksCSS}>
            <OlluiLink as={AnchorLink} extend={linkCSS} to="/#features">
              <Text bold>Features</Text>
            </OlluiLink>
            {/* <OlluiLink as={AnchorLink} extend={linkCSS} to="/#pricing">
              <Text bold>Pricing</Text>
            </OlluiLink> */}
            <OlluiLink as={AnchorLink} extend={linkCSS} to="/#faq">
              <Text bold>FAQ</Text>
            </OlluiLink>
            <OlluiLink as={Link} extend={linkCSS} to="/blog">
              <Text bold>Blog</Text>
            </OlluiLink>
          </View>
          <Spacer size={2} />
          <View as={Link} to="/" title="Logo" extend={logoCSS}>
            <img src={logo} alt="Stenkolle" style={{ height: "50px" }} />
          </View>
          <Spacer size={2} />
          <View extend={buttonContainerCSS}>
            <View
              as="a"
              href="https://app.stenkolle.com/login"
              extend={{ textDecoration: "none" }}
            >
              <Button variant="clear">Login</Button>
            </View>
            <Spacer size={2} />
            <View
              as="a"
              href="https://app.stenkolle.com/login"
              extend={{ textDecoration: "none" }}
            >
              <Button>Signup</Button>
            </View>
          </View>
        </View>
      </View>
    );
  }
};

const rootCSS = ({ theme: { colors } }) => ({
  position: "sticky",
  top: 0,
  // background: "hotpink",
  zIndex: 2,
  background: colors.bg,
  // padding: 2 * grid,
});

const containerCSS = ({ theme: { shadows, layout, grid, breakpoints } }) => ({
  maxWidth: layout.maxContentWidth,
  // boxShadow: shadows.big,
  display: "flex",
  // justifyContent: "space-between",
  margin: "0 auto",
  padding: 3 * grid,
  alignItems: "center",

  [breakpoints.onlyS]: {
    flexDirection: "column",
    padding: 2 * grid,
    paddingTop: 3 * grid,
  },
});

const linksCSS = ({ theme: { shadows, colors, grid, breakpoints } }) => ({
  display: "flex",
  // flex: 1,
  alignItems: "center",
  width: "40%",
  // background: "orange",

  [breakpoints.onlyS]: {
    // display: "none",
    position: "fixed",
    bottom: 0,
    left: 0,
    background: "white",
    // background: "hotpink",
    borderTop: `2px solid ${colors.border}`,
    // boxShadow: shadows.big,
    width: "100%",
    justifyContent: "space-between",
    padding: grid,
    zIndex: 3000000002,
    paddingRight: 100,
  },
});

const linkCSS = ({ theme: { shadows, layout, grid, breakpoints } }) => ({
  padding: `${grid}px ${2 * grid}px`,
  textDecoration: "none",

  [breakpoints.onlyS]: {
    padding: 2 * grid,
  },
});

const logoCSS = () => ({
  display: "flex",
  flex: 1,

  justifyContent: "center",
  alignItems: "center",
  // background: "red",
});

const buttonContainerCSS = ({ theme: { breakpoints } }) => ({
  display: "flex",
  width: "40%",
  // background: "orange",
  justifyContent: "flex-end",
  [breakpoints.onlyS]: {
    // flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  // [breakpoints.onlyS]: {
  //   display: "none",
  // },
});

export default Navbar;
