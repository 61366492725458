import React from "react";
import Particles from "react-particles-js";
import { View, Text, Spacer, Button } from "ollui";
import heroImage from "../img/hero.svg";

export const Hero = ({ title, subheading }) => {
  return (
    <View extend={heroCSS}>
      <View extend={heroContentCSS}>
        <Text as="h1" variant="title" bold extend={{ maxWidth: 900 }}>
          {title}
        </Text>
        <Spacer size={2} />
        <Text as="h3" variant="medium" extend={{ maxWidth: 600 }}>
          {subheading}
        </Text>
        <Spacer size={6} />
        <View
          as="a"
          href="https://app.stenkolle.com/login"
          extend={{ textDecoration: "none" }}
        >
          <Button>Get started</Button>
        </View>
        <Spacer size={2} />
        <Text diffuse variant="small">
          It's free!
        </Text>
      </View>
      <View as="img" src={heroImage} extend={heroImageCSS} />
      <View
        as={Particles}
        extend={particlesCSS}
        params={{
          particles: {
            number: {
              value: 300,
              density: {
                enable: true,
                value_area: 1000,
              },
            },
            color: {
              value: "#ccc",
            },
            opacity: {
              value: 0.5,
              anim: {
                enable: true,
              },
            },
            size: {
              value: 5,
              random: true,
              anim: {
                enable: true,
                speed: 3,
              },
            },
            line_linked: {
              enable: false,
            },
            move: {
              speed: 0.5,
            },
          },
        }}
      ></View>
    </View>
  );
};

const heroCSS = ({ theme: { breakpoints } }) => ({
  position: "relative",
  // display: "flex",
  // flexDirection: "column",
  width: "100%",
  // height: "80vh",
  // minHeight: 600,
  // justifyContent: "center",
  // alignItems: "center",
  // textAlign: "center",
  // paddingBottom: "50%",

  [breakpoints.onlyS]: {
    height: "100vh",
  },
});

const heroImageCSS = ({ theme: { colors, grid } }) => ({
  position: "relative",
});

const heroContentCSS = ({ theme: { colors, grid, breakpoints } }) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  marginBottom: -20 * grid,
  paddingTop: "10vh",
  zIndex: 1,

  [breakpoints.onlyS]: {
    paddingTop: 2 * grid,
  },
});

const particlesCSS = () => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  // height: "100%",
  // background: "orange",
});
