import React from "react";
import { View, Card, Text, Accordion } from "ollui";
import { Section } from "./section";

const faq = [
  {
    question: "How is Stenkolle free?",
    answer:
      "Initially, our costs for this service are quite low, but as we grow larger we will implement functionality to enable opt-in donations to keep the service up.",
  },
  {
    question: "Are you planning on adding support for more type of events?",
    answer:
      "Yes, the plan is to add events customizable by the users and perhaps also libraries for use with different frontend frameworks. Please send us an email if you have any thoughts or ideas on this! 🙂",
  },
  {
    question: "Can we track users over longer periods of time?",
    answer: "Nope, this goes against how Stenkolle operates.",
  },
  // {
  //   question: "How do you anonymize our sites' users?",
  //   answer:
  //     "Each user visiting your site gets instantly anonymized when the data hits our servers and a hashed unique string is saved to the database together with some basic information about the user like browser, country and so on. The salt used for this hashed string is then rotated every 24h, at which point each user ID also gets converted to a random UUID.",
  // },
  // {
  //   question: "Can I check different endpoints on my site?",
  //   answer: "At the moment no, but",
  // },
  // {
  //   question: "",
  //   answer: "",
  // },
  // {
  //   question: "",
  //   answer: "",
  // },
  // {
  //   question: "",
  //   answer: "",
  // },
  // {
  //   question: "",
  //   answer: "",
  // },
];

export const FAQ = () => {
  // console.log("FAQ!!!!!");
  return (
    <Section
      heading="Want to know more?"
      subHeading="FREQUENTLY ASKED QUESTIONS"
      id="faq"
    >
      <Card extend={rootCSS}>
        {faq.map((d) => (
          <Accordion title={d.question}>
            <Text extend={answerCSS}>{d.answer}</Text>
          </Accordion>
        ))}
      </Card>
    </Section>
  );
};
const rootCSS = () => ({
  width: "100%",
  maxWidth: 600,
  margin: "0 auto",
});

const answerCSS = ({ theme: { grid, colors } }) => ({
  padding: 2 * grid,
  background: colors.contentBg,
  textAlign: "left",
});
