import React from "react";
import { Section } from "./section";
import { View, Text, Spacer, Button } from "ollui";

export const StartTrial = () => (
  <Section>
    <View extend={rootCSS}>
      <Text variant="title">Get started now!</Text>
      <Spacer />
      <Text>It is free so feel free to give it a go...</Text>
      <Spacer size={4} />
      <View
        as="a"
        href="https://app.stenkolle.com/login"
        extend={{ textDecoration: "none" }}
      >
        <Button>Sign up now</Button>
      </View>
    </View>
  </Section>
);

const rootCSS = () => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  maxWidth: 600,
  margin: "0 auto",
});
