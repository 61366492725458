import React from "react";
import { View, Text, Spacer } from "ollui";

export const Section = ({
  as = "section",
  children,
  heading,
  extend,
  subHeading,
  variant,
  bottomWave,
  ...props
}) => (
  <View
    as={as}
    extend={[rootCSS({ hasBottomWave: !!bottomWave }), extend]}
    {...props}
  >
    {heading && (
      <Text
        variant="medium"
        color={variant === "light" ? "white" : "body"}
        bold
        extend={headingCSS}
      >
        {heading}
      </Text>
    )}
    {subHeading && (
      <>
        <Spacer />
        <Text
          variant="large"
          color={variant === "light" ? "white" : "primary"}
          bold
          extend={subHeadingCSS}
        >
          {subHeading}
        </Text>
      </>
    )}
    {(heading || subHeading) && <Spacer size={8} />}
    {children}
    {bottomWave && (
      <View
        as="svg"
        extend={bottomWaveCSS}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={bottomWave.viewBox}
      >
        {bottomWave.content}
      </View>
    )}
  </View>
);

const rootCSS = ({ hasBottomWave }) => ({ theme: { grid } }) => ({
  position: "relative",
  width: "100%",
  padding: `${12 * grid}px 0`,
  textAlign: "center",
  ...(hasBottomWave && {
    paddingBottom: 0,
  }),
  // display: "flex",
  // flexDirection: "column",
  // background: "orange",
});

const headingCSS = ({ theme: { grid } }) => ({
  // paddingTop: 12 * grid,
  // color: colors.primary,
  textTransform: "uppercase",
});

const subHeadingCSS = () => ({});

const bottomWaveCSS = ({ theme: { grid } }) => ({
  marginTop: 12 * grid,
  marginBottom: -2,
  // position: "absolute",
  // bottom: 0,
  // left: 0,
  // width: "100%",
  // // height: 200,
  // background: "red",
});
